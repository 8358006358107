<script setup>
import { reactive, onMounted, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'

import { time_controls, time_control_to_text } from '../assets/js/timecontrols.js'
import { setPageTitle } from '../assets/js/titleManager.js';
import { MegaphoneIcon, BoltIcon, ChevronDoubleUpIcon, PencilSquareIcon , ChevronLeftIcon, ChevronRightIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import ButtonLoad from './ButtonLoad.vue'
import MatchList from './MatchList.vue'
import Message from './Message.vue'
import Tooltip from './Tooltip.vue'
import UserName from './UserName.vue'
import Countdown from './Countdown.vue'
import MarkdownRenderer from './MarkdownRenderer.vue'

import {useMatchStore } from '@/stores/matchstore.js'
const matchStore = useMatchStore();

import {useSSEStore } from '@/stores/ssestore.js'
const sseStore = useSSEStore();

import {useMessageStore } from '@/stores/messagestore.js'
const messageStore = useMessageStore();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

import {useChatStore } from '@/stores/chatstore.js'
const chatStore = useChatStore();

const extra_data = reactive({
    offset:0,
    user_data: [],
    match_list: [],
    show_leaderboard: false,
    show_winlose: true,
    can_queue: true,
    can_signup: true,
    info: null
});

const club_data = reactive({
    join_club_id: null,
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const route = useRoute();
const club_id = route.params.club_id;

onMounted(() => {
    reload();
    matchStore.setup();
});

function reload(){
    get_club_info();
}

async function get_club_info(){
    var url = app_server + `/club/${club_id}/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    })    

    const data = await response.json();
    console.log(data);

    extra_data.info = data.club;
    extra_data.members = data.members;

    extra_data.members.sort( (b,a) => a.rating - b.rating);

    if (extra_data.info && extra_data.info.name) {
        setPageTitle(extra_data.info.name);
    }
}

async function affiliate(){
    var url = app_server + `/club/${club_id}/affiliate/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    })    

    const data = await response.json();

    if(data.status == "success"){
        messageStore.alertUser("Success", 
            `You are now affiliated to the ${extra_data.info.name} club.`
        );
        userStore.loadUser(true);
    }
}

async function unaffiliate(){
    var url = app_server + `/club/${club_id}/unaffiliate/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    })    

    const data = await response.json();

    if(data.status == "success"){
        messageStore.alertUser("Success", 
            `You stopped affiliatiing yourself to the ${extra_data.info.name} club.`
        );
        userStore.loadUser(true);
    }
}

async function create_match(match_settings){
    const create_match_settings = Object.assign({}, match_settings);
    create_match_settings.visibility = get_visibility();
    console.log("Creating match", extra_data.selected_choice);

    const match_data = await matchStore.create_joinrequest(create_match_settings);
}

async function join_chat_lobby(){
    const chat = await chatStore.join_club(club_id);

    if(chat != null){
        messageStore.alertUser("Joined Lobby", "You joined the club lobby.");
    }else{
        messageStore.alertUser("Failed", "You cannot join the club lobby.");
    }
}

function is_member(){
    return extra_data.info.members.includes(userStore.info.user_id);
}
function is_moderator(){
    return extra_data.info.creator == userStore.info.user_id || 
           extra_data.info.moderators.includes(userStore.info.user_id);
}
function get_visibility(){
    return `club_${club_id}`;
}

</script>

<template>
<Header />
<Message />
<div class="flex flex-col md:flex-row gap-x-12 gap-y-8 md:h-rest">
    <div class="flex flex-col px-2 max-w-96">
        <!-- Club Information -->
        <div v-if="extra_data.info" class="">
            <h1 class="text-4xl font-semibold mt-4 flex items-center gap-x-8">
                <span class="grow">
                    {{ extra_data.info.tag}}
                </span>
                <router-link :to="{name: 'club-mod', params: {club_id: club_id}}"
                    v-if="is_moderator()"
                >
                    <PencilSquareIcon class="btn btn-blue !p-0.5 size-6" /> 
                </router-link>

                <Tooltip text="Join the club lobby">
                <MegaphoneIcon v-if="is_member()"
                    class="size-6 transition opacity-60 hover:opacity-100 cursor-pointer" 
                    @click="join_chat_lobby()"
                />
                </Tooltip>
            </h1>
            <h2 class="text-2xl my-4">
                {{ extra_data.info.name }}
            </h2>
            <h2 class="text-lg mt-1 font-thin flex gap-x-2">
                Club Director: <UserName :user_id="extra_data.info.creator"/>
            </h2>
            <h2 class="text-lg mt-1 font-thin flex gap-x-2">
                Rating: {{extra_data.info.rating.toFixed(0) }}
            </h2>
            <h2 class="text-lg mt-1 font-thin flex gap-x-2">
                Experience: {{extra_data.info.experience.toFixed(0) }}
            </h2>
            <div v-if="extra_data.info.description && extra_data.info.description.length > 0"
                class="text-lg font-thin w-96 my-4 p-4 isolate bg-main-2-color rounded-sm shadow-md"   
            >
                <MarkdownRenderer 
                    :markdown="extra_data.info.description" 
                    :save="false" 
                />
            </div>
            <div v-if="is_member()">
                <ButtonLoad v-if="userStore.info.club_id != extra_data.info.tag"
                    class="btn btn-blue h-12 w-full" 
                    :fn="affiliate"
                >
                    Affiliate
                </ButtonLoad>
                <ButtonLoad v-else
                    class="btn btn-blue h-12 w-full" 
                    :fn="unaffiliate"
                >
                    Unaffiliate
                </ButtonLoad>
            </div>
        </div>
    
        <!-- Leaderboard -->
        <div class="w-full md:w-80 md:block">
            <span class="flex justify-between items-center">
                <h1 class="text-4xl font-semibold mt-4">Leaderboard</h1> 
                <button class="btn btn-blue md:hidden py-2 text-sm h-6" 
                        v-show="!extra_data.show_leaderboard"
                        @click="extra_data.show_leaderboard = true"
                >
                    Show
                </button>
                <button class="btn btn-red md:hidden text-sm"
                        v-show="extra_data.show_leaderboard"
                        @click="extra_data.show_leaderboard = false"
                >
                    Hide
                </button>
            </span>
            <table class="w-full h-full overflow-y-auto my-4" 
                   :class="{
                        'table': extra_data.show_leaderboard, 
                        'md:table hidden': !extra_data.show_leaderboard
                    }"
            >
                <thead>
                    <tr>
                        <th></th>
                        <th class="text-left w-36">User</th>
                        <th>
                            Rating
                        </th>
                        <th>
                             ER
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="user_data, index in extra_data.members">
                        <td class="">{{ index + 1 }})</td> 
                        <td class="text-left text-elipsis">
                            <UserName :user="user_data" />
                        </td>
                        <td class="text-center tabular-nums cursor-pointer">
                            {{ user_data.rating.toFixed(0) }}
                        </td>
                        <td class="text-right tabular-nums">
                            {{ user_data.er.toFixed(2) }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    <div class="grow md:h-full">
        <h1 class="text-4xl font-semibold mt-4 mb-4 text-center">Club Matches</h1>
        <div class="flex gap-x-4 px-2 my-4" v-if="extra_data.info && is_member">
            <ButtonLoad v-for="[name, settings] in Object.entries(extra_data.info.match_presets).sort( (a,b) => a[1].order - b[1].order)"
                        :fn="() => create_match(settings)"
                        class="btn btn-blue h-10"
            >
                {{ name }}
            </ButtonLoad>
        </div>
        <MatchList 
            :visibility="get_visibility()"
        />
    </div>
</div>
</template>


