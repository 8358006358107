import { defineStore } from 'pinia'
import {useMessageStore } from '@/stores/messagestore.js'
import {useSSEStore } from '@/stores/ssestore.js'
import {useUserStore } from '@/stores/userstore.js'

import {updateTitleWithNotifications} from '../assets/js/titleManager.js'

const app_server = import.meta.env.VITE_APP_SERVER;

function chat_compare(a, b){
    if(a == null || a.messages == null || a.messages.length == 0){
        return -1;
    }
    if(b == null || b.messages == null || b.messages.length == 0){
        return 1;
    }
    return -(a.messages.at(-1).timestamp - b.messages.at(-1).timestamp)
}

export const useChatStore = defineStore('chat', {
    state: () => { return {
        chats: {},
        lobbies: {},
        active_chats: [],
        user: {},
        timeout: false,
        featured_lobbies: [
            "general",
            "support",
            "matchup",
        ],
    };},
    getters: {
        unread: (state) => Math.min(
            99, 
            Object.values(state.chats).reduce((ps, x) => 
                ps + (x.unread || 0), 0)
        ), 
        unread_chats: function(){ 
            return Math.min(99, this.sorted_chats.reduce( (ps, x) => ps + (x.unread || 0), 0));
        },
        unread_lobbies: function(){
            return Math.min(99, this.sorted_lobbies.reduce( (ps, x) => ps + (x.unread || 0), 0));
        },
        unread_clubs: function(){
            return Math.min(99, this.sorted_clubs.reduce( (ps, x) => ps + (x.unread || 0), 0));
        },
        sorted_chats: (state) => {
            if(Object.keys(state.chats).length == 0){
                return []
            }
            return Object.values(state.chats).filter(x => 
                x.lobby.split(":").length > 2).sort(chat_compare);
        },
        sorted_lobbies: (state) => {
            if(Object.keys(state.chats).length == 0){
                return []
            }

            return Object.values(state.chats).filter(x => 
                x.lobby.startsWith("chat:lobby_")).sort(chat_compare);
        },
        sorted_clubs: (state) => {
            if(Object.keys(state.chats).length == 0){
                return []
            }

            return Object.values(state.chats).filter(x => 
                x.lobby.startsWith("chat:club_")).sort(chat_compare);
        },
    },
    actions:{
        async start_chat(username){
            const response = await fetch(app_server + `/chat/${username}/start/`, {
                method: "GET",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
            });
            const data = await response.json();
            if(data.status == "success"){
                await this.get_chat(data.lobby);
                return this.chats[data.lobby];
            }else{
                console.error("Error starting chat", data);
                if(data.code == 403){
                    const messageStore = useMessageStore();
                    messageStore.alertUser("Chat Timeout", "You currently cannot chat due to a timeout.");
                    this.timeout = true;
                }
            }
        },
        async leave_chat(chat_key){
            const response = await fetch(app_server + `/chat/${chat_key}/leave/`, {
                method: "GET",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
            });

            const data = await response.json();

            if(data.status == "success"){
                this.get_active_chats();
                const messageStore = useMessageStore();
                messageStore.alertUser("Left Chat", "You left the chat, you won't receive any messages.");
            }

        },
        async join_lobby(lobby_name){
            const response = await fetch(app_server + `/chat/${lobby_name}/lobby/`, {
                method: "GET",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
            });
            const data = await response.json();
            if(data.status == "success"){
                await this.get_chat(data.lobby);
                return this.chats[data.lobby];
            }else{
                console.error("Error starting chat", data);
                if(data.code == 403){
                    const messageStore = useMessageStore();
                    messageStore.alertUser("Chat Timeout", "You currently cannot chat due to a timeout.");
                    this.timeout = true;
                }
            }
        },
        async join_club(club_id){
            const response = await fetch(app_server + `/chat/${club_id}/club/`, {
                method: "GET",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
            });
            const data = await response.json();
            if(data.status == "success"){
                await this.get_chat(data.lobby);
                return this.chats[data.lobby];
            }else{
                console.error("Error starting chat", data);
                if(data.code == 403){
                    const messageStore = useMessageStore();
                    messageStore.alertUser("Chat Timeout", "You currently cannot chat due to a timeout.");
                    this.timeout = true;
                }
                return null;
            }
        },
        async get_chat(chat_key){
            if(chat_key.startsWith("BOT_")){
                this.bot_chat(chat_key.slice(4), "");
                return;
            }
            const response = await fetch(app_server + `/chat/${chat_key}/`, {
                method: "GET",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
            });
            const data = await response.json();
            if(data.status == "success"){
                this.chats[data.lobby] = {
                    lobby : data.lobby,
                    others: data.others,
                    name: this.get_lobby_name(data),
                    info: data.info,
                    messages: data.messages,
                };
                this.update_unread(chat_key);
            }else{
                if(data.code == 403){
                    const messageStore = useMessageStore();
                    messageStore.alertUser("Chat Timeout", "You currently cannot chat due to a timeout.");
                    this.timeout = true;
                }
            }
        },
        async send_chat(chat_key, message){
            if(message == null || message.length == 0){
                return;
            }
            const response = await fetch(app_server + `/chat/${chat_key}/`, {
                method: "POST",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
                body: JSON.stringify({
                    message: message,
                }),
            });
            const data = await response.json();

            if(data.status == "error"){
                // TODO error
                if(data.code == 403){
                    const messageStore = useMessageStore();
                    messageStore.alertUser("Chat Timeout", "You currently cannot chat due to a timeout.");
                    this.timeout = true;
                }
            }else{
                if(data.message_sent.sender == "system"){
                    this.chats[data.lobby].messages.push(data.message_sent);
                }
                const lastread_key = `lastread:${this.user.user_id}`;
                this.chats[chat_key].info[lastread_key] = Date.now() / 1000;
                this.update_unread(chat_key);
            }
        },
        async get_active_chats(){
            const response = await fetch(app_server + `/chat/`, {
                method: "GET",
                mode: "cors",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
            });
            const data = await response.json();
            if(data.status == "success"){
                this.chats = {}; // Hopefully this will fix the problem with chats not appearing
                this.lobbies = {};
                this.active_chats = data.active_chats;
                this.active_lobbies = data.active_lobbies;
                this.load_active_chats();
            }else{
                if(data.code == 403){
                    this.timeout = true;
                }
            }
        },
        async load_active_chats(limit=5){
            for(var i=0; i < limit; i++){
                if(this.active_chats.length == 0){
                    break;
                }
                const next_chat_key = this.active_chats.shift();
                await this.get_chat(next_chat_key);
            }
            for(var i=0; i < limit; i++){
                if(this.active_lobbies.length == 0){
                    break;
                }
                const next_chat_key = this.active_lobbies.shift();
                await this.get_chat(next_chat_key);
            }
        },
        async connect(){
            await this.connect_sse();
            this.get_active_chats();

            const userStore = useUserStore();
            await userStore.loadUser();
            this.user = userStore.info;
        },
        async connect_sse(){
            const sseStore = useSSEStore();
            await sseStore.connect();
            sseStore.addListener("chat", (data) => {
                if(this.chats[data.lobby] == null){
                    this.get_chat(data.lobby);
                }else{
                    this.chats[data.lobby].messages.push(data.message);
                    this.update_unread(data.lobby);
                    this.last_message = data.message;
                }
            });
        },
        disconnect(){

        },
        get_lobby_name(chat){
            if(chat.lobby.startsWith("BOT_")){
                return chat.lobby.slice(4);
            }
            const lobby_regex = /chat:(?:lobby|club)_(\w+)/;
            const match = chat.lobby.match(lobby_regex);

            if(match != null){
                return match[1];
            }else if(Object.values(chat.others).length == 1){
                return Object.values(chat.others)[0].username;
            }        

            return "None"
        },
        update_unread(chat_key){
            const lastread_key = `lastread:${this.user.user_id}`;
            const chat = this.chats[chat_key];
            if(chat == null || chat.info == null){
                return;
            }
            const last_read = parseFloat(chat.info[lastread_key] || 0);
            const unread_messages = chat.messages.filter(x => 
                x.timestamp > last_read && x.sender != this.user.user_id && x.sender != null);
            this.chats[chat_key].unread = unread_messages.length;
            this.update_title();
        },
        set_read(chat_key){
            const chat = this.chats[chat_key];
            if(chat.unread == 0){
                return;
            }
            const lastread_key = `lastread:${this.user.user_id}`;
            if(chat == null){
                return;
            }
            chat.info[lastread_key] = Date.now()/1000;
            this.update_unread(chat_key);
        },
        update_title() {
            updateTitleWithNotifications(this.unread);
        },
        
        bot_chat(bot_name, msg){
            const lobby = `BOT_${bot_name}`;
            if(this.chats[lobby] == null){
                this.chats[lobby] = {
                    lobby: lobby,
                    name: bot_name,
                    info: {},
                    messages: [],
                    others: {},
                };
                this.chats[lobby].others[bot_name] = {username: bot_name};
            }
            const message = {
                "sender": bot_name,
                "timestamp": Date.now() / 1000,
                "message": msg,
            }
            if(msg && msg.length > 0){
                this.chats[lobby].messages.push(message);
                this.last_message = message;
            }
            this.update_unread(lobby)
        },

    }


});

