<script setup>

import {ref, watch, computed, reactive, onMounted, onUnmounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'
import { Match } from '../assets/js/match.js'

import Tooltip from './Tooltip.vue'

const router = useRouter();

const app_server = import.meta.env.VITE_APP_SERVER;
const emit = defineEmits([
    'alarm',
]);

const props = defineProps({
    show: {
        type: Boolean,
        default: true,    
    },
    until: {
        type: Number,
        default: null,
    },
}
);

var counter_interval_id = null;

const extra_data = reactive({
    counter: 0,
});

onMounted(() => {
    start_counter();
    document.addEventListener("visibilitychange", () => {
        extra_data.background = Document.hidden;

        if(extra_data.background){
            clearInterval(counter_interval_id);
        }else{
            start_counter();
        }
    });
});

onUnmounted( () => {
    clearInterval(counter_interval_id);
});

watch(props, async (newProps, oldProps) => {
});

function start_counter(){
    clearInterval(counter_interval_id);

    if(props.until == null){ // no counter 
        return;
    }
    extra_data.counter = parseInt((props.until - Date.now()/1000) );
    counter_interval_id = setInterval(() => {
        extra_data.counter = parseInt((props.until - Date.now()/1000) );

        if(extra_data.counter < 0){
            emit("alarm");
            extra_data.counter = 0;
            clearInterval(counter_interval_id);
        }
    }, 200);
}

function get_time(seconds){
    /*
    * returns a human reabable time string (bit overcomplicated, but wanted it to
    * be nicely formatted.
    */
    const months = Math.floor(seconds / (30*24*60*60)).toString();
    const days = Math.floor((seconds % (30*24*60*60)) / (24*60*60)).toString();
    const hours = Math.floor((seconds % (24*60*60)) / (60*60)).toString();
    const minutes = Math.floor((seconds % (60*60)) / 60).toString();
    const sec = Math.floor(seconds % 60).toString();

    var time = 0;
    var unit = ""

    if(months > 0){
        time = months;
        unit = `months`;
    }else if(days > 0){
        time = days;
        unit = `days`;
    }else if(hours > 0){
        time = hours;
        unit = `hours`;
    }else if(seconds >= 60){
        time = minutes;
        unit = `minutes`
    }else{
        time = sec; 
        unit = "seconds";
    }

    if(time == 1){
        unit = unit.slice(0, -1);
    }
    
    return `${time} ${unit}`;
}

</script>
<template>
    <span class="">
        {{ get_time(extra_data.counter) }}
    </span>
</template>

<style scoped>
</style>

