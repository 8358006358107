<script setup>
import {watch, ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon} from '@heroicons/vue/24/solid'
import PatronIcon from './PatronIcon.vue'

import UserTag from './UserTag.vue'

const router = useRouter();

import {useUserStore } from '@/stores/userstore.js'
const userStore = useUserStore();

const app_server = import.meta.env.VITE_APP_SERVER;

const props = defineProps({
    user: { 
        type: Object,
        default: null,
    },
    user_id: {
        type: String,
        default: null,
    },
    link_to_userview: {
        type: Boolean,
        default: true,
    },
});

const user_data = reactive({
    online: false,
    username : "",
    tag: "",
    is_patron: "",
    user_id: null,
});

async function get_user_info(){
    const response = await fetch(app_server + `/user/${props.user_id}/info/`, {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    const data = await response.json();
    Object.assign(user_data, data.user);
}

watch(props, () => {
    if(props.user == null){
        return
    }else{
        user_data.user_id = props.user.user_id;
        user_data.online = userStore.is_online(props.user.user_id);
        user_data.username = props.user.username;
        user_data.tag = props.user.tag;
        user_data.is_patron = props.user.is_patron;
        user_data.club = props.user.club_id;
    }
});

watch(userStore.users_online, () => {
    user_data.online = userStore.users_online.includes(user_data.user_id);
});

onMounted(async () => {
    if(props.user_id != null){
        get_user_info();
    }
    else if(props.user == null){
        await userStore.loadUser();
        user_data.user_id = userStore.info.user_id;
        user_data.online = userStore.is_online(userStore.info.user_id);
        user_data.username = userStore.info.username;
        user_data.tag = userStore.info.tag;
        user_data.is_patron = userStore.info.is_patron;
        user_data.club = userStore.info.club_id;
    }else{
        user_data.user_id = props.user.user_id;
        user_data.online = userStore.is_online(props.user.user_id);
        user_data.username = props.user.username;
        user_data.tag = props.user.tag;
        user_data.is_patron = props.user.is_patron;
        user_data.club = props.user.club_id;
    }
});

</script>
<template>
    <div class="flex items-center gap-x-1">
        <PatronIcon v-if="user_data.is_patron"
                    class="size-5 -mr-1 " 
                    :class="{
                        'fill-accent-3-color stroke-accent-color' : user_data.online,
                        'fill-main-5-color stroke-main-5-color' : !user_data.online,
                    }"
        />
        <div class="size-5 p-1 -mr-1 relative" v-else>
            <div class="rounded-full w-full h-full" 
                :class="{'border-2 bg-accent-2-color border-accent-3-color' : user_data.online}"
            />
        </div>
        <router-link v-if="props.link_to_userview && user_data.user_id != null" 
            :to="{name:'user-info', params:{user_id: user_data.user_id}}"
            class="hover:underline"
        >
        <span v-if="user_data.club">
            <span class="tracking-tighter font-mono club-tag">
                {{ user_data.club }}
            </span>&#8239;</span>{{ user_data.username }} <UserTag :tag="user_data.tag" />
        </router-link>
        <span v-else>
            <span v-if="user_data.club"><span class="tracking-tighter font-mono">{{ user_data.club }}</span>&#8239;</span>{{ user_data.username }} <UserTag :tag="user_data.tag" />
        </span>
    </div>
</template>

<style>
.club-tag{
    font-variant-caps: all-small-caps;
    font-stretch: narrower;
}
</style>

