export function setPageTitle(newTitle) {
    const baseTitle = 'OpenGammon';

    const merged_title = ( newTitle )
        ? `${newTitle} - ${baseTitle}` 
        : baseTitle;

    document.title = merged_title;
}
  
export function updateTitleWithNotifications(count) {
    /*
     * This function does not do what it is supposed to do.
     *
     * If we for example have the string "Daily Puzzle - OpenGammon", the baseTitle
     * will be "OpenGammon", removing the notification count. We either need to use
     * a different separator for the notification count or do this via a regex.
     */
    return;
  const currentTitle = document.title;
  const baseTitleParts = currentTitle.split(' - ');
  const baseTitle = baseTitleParts[baseTitleParts.length - 1];

  if (count > 0) {
    document.title = `(${count}) - ${baseTitle}`;
  } else {
    document.title = baseTitle;
  }
}
