import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import { createHead } from '@unhead/vue'
import { createPinia } from 'pinia'
import { registerSW } from 'virtual:pwa-register'
import './index.css'

import Home from './components/Home.vue'
import Analysis from './components/Analysis.vue'
import Spectate from './components/Spectate.vue'
import Game from './components/Game.vue'
import UserDetail from './components/UserDetail.vue'
import UserVsDetail from './components/UserVsDetail.vue'
import LearnMenu from './components/LearnMenu.vue'
import PuzzleMenu from './components/PuzzleMenu.vue'
import Puzzle from './components/Puzzle.vue'
import DailyPuzzle from './components/DailyPuzzle.vue'
import PuzzleCommentList from './components/PuzzleCommentList.vue'
import Position from './components/Position.vue'
import PostMatch from './components/PostMatch.vue'
import MatchList from './components/MatchList.vue'
import MatchMenu from './components/MatchMenu.vue'
import DailyMatch from './components/DailyMatch.vue'
import DailyMatchList from './components/DailyMatchList.vue'
import UserMatchList from './components/UserMatchList.vue'
import AccountDetails from './components/AccountDetails.vue'
import ArenaInfo from './components/ArenaInfo.vue'
import TournamentInfo from './components/TournamentInfo.vue'
import TournamentList from './components/TournamentList.vue'
import TournamentMod from './components/TournamentMod.vue'
import ClubInfo from './components/ClubInfo.vue'
import ClubList from './components/ClubList.vue'
import ClubMod from './components/ClubMod.vue'
import Leaderboard from './components/Leaderboard.vue'
import Achievements from './components/Achievements.vue'
import Quiz from './components/Quiz.vue'
import AvatarBuilder from './components/AvatarBuilder.vue'
import ThemeBuilder from './components/ThemeBuilder.vue'
import ThemeList from './components/ThemeList.vue'
import UserThemes from './components/UserThemes.vue'
import BoardGif from './components/BoardGif.vue'
import BlogArticle from './components/BlogArticle.vue'
import BlogList from './components/BlogList.vue'

import Lesson from './components/Lesson.vue'
import LessonList from './components/LessonList.vue'
import LessonCreate from './components/LessonCreate.vue'
import Course from './components/Course.vue'
import CourseList from './components/CourseList.vue'

import BotGame from './components/BotGame.vue'
import BotList from './components/BotList.vue'

import Donate from './components/Donate.vue'
import DonateSuccess from './components/DonateSuccess.vue'

import ModerateReports from './components/ModerateReports.vue'
import ModerateUser from './components/ModerateUser.vue'

import AdminHome from './components/AdminHome.vue'
import AdminUserList from './components/AdminUserList.vue'
import AdminUserMail from './components/AdminUserMail.vue'
import AdminUserDetail from './components/AdminUserDetail.vue'
import AdminMatchList from './components/AdminMatchList.vue'
import AdminMatchDetail from './components/AdminMatchDetail.vue'
import AdminArenaList from './components/AdminArenaList.vue'
import AdminArenaDetail from './components/AdminArenaDetail.vue'
import AdminTournamentList from './components/AdminTournamentList.vue'
import AdminTournamentDetail from './components/AdminTournamentDetail.vue'
import AdminPuzzleList from './components/AdminPuzzleList.vue'
import AdminCommentList from './components/AdminCommentList.vue'
import AdminQuizList from './components/AdminQuizList.vue'
import AdminSolveList from './components/AdminSolveList.vue'
import AdminBotList from './components/AdminBotList.vue'
import AdminBotDetail from './components/AdminBotDetail.vue'
import AdminThemeDetail from './components/AdminThemeDetail.vue'
import AdminAnalysisList from './components/AdminAnalysisList.vue'
import AdminReportList from './components/AdminReportList.vue'
import AdminReport from './components/AdminReport.vue'
import AdminMaintenance from './components/AdminMaintenance.vue'
import AdminAnalyserList from './components/AdminAnalyserList.vue'
import AdminAnalyserDetail from './components/AdminAnalyserDetail.vue'
import AdminFlairList from './components/AdminFlairList.vue'
import AdminFlairDetail from './components/AdminFlairDetail.vue'
import AdminClubList from './components/AdminClubList.vue'
import AdminClubDetail from './components/AdminClubDetail.vue'

import AdminBlogList from './components/AdminBlogList.vue'
import AdminBlogDetail from './components/AdminBlogDetail.vue'

import AdminCourseCreate from './components/AdminCourseCreate.vue'
import AdminCourseList from './components/AdminCourseList.vue'
import AdminLessonList from './components/AdminLessonList.vue'

import Support from './components/Support.vue'
import ToS from './components/ToS.vue'
import Features from './components/Features.vue'
import FrontPage from './components/Frontpage.vue'
import ColorProfiles from './components/ColorProfiles.vue'


const SW_update_interval = 3*60*1000;
var SW_update_interval_id = null;

function show_update_alert(registration){
    alert("A new version of the app is available.");
    if(registration.waiting){
        registration.waiting.postMessage('SKIP_WAITING');
    }
}

const updateSW = registerSW({
    immediate : true,
    onRegisteredSW(swUrl, r) {
        console.log("Registered SW", swUrl);
        if(r){
            clearTimeout(SW_update_interval_id);
            SW_update_interval_id = setInterval(async () => {
                if (!(!r.installing && navigator)){
                    return
                }

                if (('connection' in navigator) && !navigator.onLine){
                    return
                }
                if (r.waiting) {
                    show_update_alert(r);
                }
                console.log("Updating SW");
                await r.update()

                }, 
            SW_update_interval)
        }
    }
});

if ('serviceWorker' in navigator) {
    let refreshing = false;
    navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (!refreshing) {
            window.location.reload()
            refreshing = true;
        }
    });
}


const routes = [
    {path: "/", component: Home, name:"home"},
    {path: "/match/:match_id/", component: Game, name:"match", meta: {title: "Match"}},
    {path: "/match/:match_id/post/", component: PostMatch, name:"post", meta: {title: "Post Match"}},
    {path: "/match/:match_id/analysis/", component: Analysis, name:"analysis", meta: {title: "Match Analysis"}},
    {path: "/match/:match_id/spectate/", component: Spectate, name:"spectate", meta: {title: "Spectate Match"}},
    {path: "/match/", component: MatchMenu, name:"match-menu", meta: {title: "Match Menu"}},

    {path: "/daily/", component: DailyMatchList, name:"daily-list", meta: {title: "Daily Matches"}},
    {path: "/daily/:match_id/", component: DailyMatch, name:"daily", meta: {title: "Daily Match"}},

    {path: "/user/:user_id/", component: UserDetail, name:"user-info", meta: {title: "Player Profile"}},
    {path: "/user/:user1_id/vs/:user2_id/", component: UserVsDetail, name:"user-vs-info", meta: {title: "Player Comparison"}},
    {path: "/user/matches/", component: UserMatchList, name:"user-matches", meta: {title: "Match History"}},
    {path: "/user/account/", component: AccountDetails, name:"user-account", meta: {title: "Account"}},
    {path: "/user/avatar/", component: AvatarBuilder, name:"user-avatar", meta: {title: "Avatar Editor"}},

    {path: "/arena/:tournament_id/info/", component: ArenaInfo, name:"arena-info", meta: {title: "Arena"}},
    {path: "/tournament/", component: TournamentList, name:"tournament-list", meta: {title: "Tournaments"}},
    {path: "/tournament/:tournament_id/info/", component: TournamentInfo, name:"tournament-info", meta: {title: "Tournament"}},
    {path: "/tournament/:tournament_id/moderator/", component: TournamentMod, name:"tournament-mod", meta: {title: "Moderate Tournament"}},

    {path: "/puzzle/", component: PuzzleMenu, name:"puzzle-menu", meta: {title: "Puzzles"}},
    {path: "/puzzle/comment/", component: PuzzleCommentList, name:"puzzle-comments", meta: {title: "Puzzle Comments"}},
    {path: "/puzzle/:puzzle_id/", component: Puzzle, name:"puzzle", meta: {title: "Puzzle", noAuthRequired: true}},
    {path: "/quiz/:quiz_id/", component: Quiz, name:"quiz", meta: {title: "Quiz"}},
    {path: "/quiz/puzzlerush/:quiz_id/", component: Quiz, name:"quiz-rush", meta: {title: "Puzzle Rush"}},
    {path: "/quiz/piprush/:quiz_id/", component: Quiz, name:"quiz-piprush", meta: {title: "Pip Rush"}},
    {path: "/puzzle/daily/", component: DailyPuzzle, name:"daily-puzzle", meta: {title: "Daily Puzzle", noAuthRequired: true}},

    {path: "/position/:position_id/", component: Position, name:"position", meta: {title: "Position Editor"}},

    {path: "/club/", component: ClubList, name:"club-list", meta: {title: "Clubs"}},
    {path: "/club/:club_id/info/", component: ClubInfo, name:"club-info", meta: {title: "Club"}},
    {path: "/club/:club_id/moderator/", component: ClubMod, name:"club-mod", meta: {title: "Moderate Club"}},

    {path: "/bot/match/:bot_id/", component: BotGame, name:"bot-match", meta: {title: "Bot Match"}},
    {path: "/bot/", component: BotList, name:"bot", meta: {title: "Bots"}},

    {path: "/leaderboard/", component: Leaderboard, name:"leaderboard", meta: {title: "Leaderboard"}},
    {path: "/achievements/", component: Achievements, name:"achievements", meta: {title: "Achievements"}},

    {path: "/theme/builder/", component: ThemeBuilder, name:"theme-builder", meta: {title: "Theme Editor"}},
    {path: "/theme/", component: ThemeList, name:"theme", meta: {title: "Themes"}},
    {path: "/export-gif/:match_id?/", component: BoardGif, name:"export-animation", meta: {title: "Export Animation"}},
    {path: "/user/:user_id/theme/", component: UserThemes, name:"user-theme", meta: {title: "User Themes"}},

    {path: "/learn/", component: LearnMenu, name:"learn-menu", meta: {title: "Learn"}},

    {path: "/blog/backgammon/", component: BlogList, name:"blog-list", meta: {title: "Blog", noAuthRequired: true}},
    {path: "/blog/", redirect: {name: "blog-list"}},
    {path: "/blog/backgammon/:path*/:slug/", component: BlogArticle, name:"blog-article", meta: {title: "Blog Article", noAuthRequired: true}},
    {path: "/blog/:path*/:slug/", redirect: {name: "blog-article"}},

    {path: "/lesson/", component: LessonList, name:"lesson-list", meta: {title: "Lessons"}},
    {path: "/lesson/:slug/", component: Lesson, name: "lesson", meta: {title: "Lesson"}},
    {path: "/lesson/edit/:lesson_id?/", component: LessonCreate, name: "lesson-create", meta: {title: "Create Lesson"}},
    {path: "/course/", component: CourseList, name:"course-list", meta: {title: "Courses"}},
    {path: "/course/:slug/", component: Course, name: "course", meta: {title: "Course"}},

    {path: "/support/", component: Support, name:"support", meta: {title: "Support"}},
    {path: "/features/", component: Features, name:"features", meta: {title: "Features", noAuthRequired: true}},
    {path: "/donate/", component: Donate, name: "donate", meta: {title: "Donate", noAuthRequired: true}},
    {path: "/donate/success/", component: DonateSuccess, name: "donate-success", meta: {title: "Thank You", noAuthRequired: true}},

    {path: "/mod/reports", component: ModerateReports, name: "mod-reports", meta: {title: "Moderation Reports"}},
    {path: "/mod/report/user/:report_id/", component: ModerateUser, name: "mod-report-user", meta: {title: "Moderate User"}},

    // Admin routes
    {path: "/admin/", component: AdminHome, name:"admin-home", meta: {title: "Admin - Dashboard"}},
    {path: "/admin/user/", component: AdminUserList, name:"admin-users", meta: {title: "Admin - Users"}},
    {path: "/admin/user/mail/", component: AdminUserMail, name:"admin-mail", meta: {title: "Admin - Mail"}},
    {path: "/admin/user/:user_id/", component: AdminUserDetail, name:"admin-user-detail", meta: {title: "Admin - User Details"}},
    {path: "/admin/user/:user_id/solve/", component: AdminSolveList, name:"admin-user-solvelist", meta: {title: "Admin - User Solves"}},
    {path: "/admin/match/", component: AdminMatchList, name:"admin-matches", meta: {title: "Admin - Matches"}},
    {path: "/admin/match/:match_id/", component: AdminMatchDetail, name:"admin-match-detail", meta: {title: "Admin - Match"}},
    {path: "/admin/arena/", component: AdminArenaList, name:"admin-arenas", meta: {title: "Admin - Arenas"}},
    {path: "/admin/arena/:tournament_id/", component: AdminArenaDetail, name:"admin-arena-detail", meta: {title: "Admin - Arena"}},
    {path: "/admin/tournament/", component: AdminTournamentList, name:"admin-tournaments", meta: {title: "Admin - Tournaments"}},
    {path: "/admin/tournament/:tournament_id/", component: AdminTournamentDetail, name:"admin-tournament-detail", meta: {title: "Admin - Tournament"}},
    {path: "/admin/puzzle/", component: AdminPuzzleList, name:"admin-puzzles", meta: {title: "Admin - Puzzles"}},
    {path: "/admin/puzzle/:puzzle_id/", component: Puzzle, name:"admin-puzzle-detail", meta: {title: "Admin - Puzzle"}},
    {path: "/admin/puzzle/comment/", component: AdminCommentList, name:"admin-comments", meta: {title: "Admin - Comments"}},
    {path: "/admin/quiz/", component: AdminQuizList, name:"admin-quizzes", meta: {title: "Admin - Quizzes"}},
    {path: "/admin/bot/", component: AdminBotList, name:"admin-bots", meta: {title: "Admin - Bots"}},
    {path: "/admin/bot/:bot_id/", component: AdminBotDetail, name:"admin-bot-detail", meta: {title: "Admin - Bot"}},
    {path: "/admin/theme/:theme_id/", component: AdminThemeDetail, name:"admin-theme-detail", meta: {title: "Admin - Theme"}},
    {path: "/admin/analysis/", component: AdminAnalysisList, name:"admin-analysis", meta: {title: "Admin - Analysis"}},
    {path: "/admin/analyser/", component: AdminAnalyserList, name:"admin-analysers", meta: {title: "Admin - Analysers"}},
    {path: "/admin/analyser/:analyser_id/", component: AdminAnalyserDetail, name:"admin-analyser-detail", meta: {title: "Admin - Analyser"}},
    {path: "/admin/report/", component: AdminReportList, name:"admin-report", meta: {title: "Admin - Reports"}},
    {path: "/admin/usage/", component: AdminReport, name:"admin-usage", meta: {title: "Admin - Usage"}},
    {path: "/admin/maintenance/", component: AdminMaintenance, name: "admin-maintenance", meta: {title: "Maintenance"}},

    {path: "/admin/blog/", component: AdminBlogList, name:"admin-blog", meta: {title: "Admin - Blog"}},
    {path: "/admin/blog/:slug/", component: AdminBlogDetail, name:"admin-blog-detail", meta: {title: "Admin - Blog Post"}},

    {path: "/admin/lesson/", component: AdminLessonList, name:"admin-lesson-list", meta: {title: "Admin - Lessons"}},
    {path: "/admin/course/", component: AdminCourseList, name:"admin-course-list", meta: {title: "Admin - Courses"}},
    {path: "/admin/course/:course_id?/", component: AdminCourseCreate, name:"admin-course-detail", meta: {title: "Admin - Course"}},

    {path: "/admin/flair/", component: AdminFlairList, name:"admin-flair", meta: {title: "Admin - Flair"}},
    {path: "/admin/flair/:flair_id/", component: AdminFlairDetail, name:"admin-flair-detail", meta: {title: "Admin - Flair"}},

    {path: "/admin/club/", component: AdminClubList, name:"admin-club", meta: {title: "Admin - Club"}},
    {path: "/admin/club/:club_id/", component: AdminClubDetail, name:"admin-club-detail", meta: {title: "Admin - Club"}},
    // Static Routes
    {path: "/faq/", redirect: {name: 'blog-article', params:{slug: 'faq'}}, meta: {title: "FAQ", noAuthRequired: true}},
    {path: "/static/tos/", component: ToS, name:"static-tos", meta: {title: "Terms of Service", noAuthRequired: true}},
    {path: "/static/boardprofiles/", component: ColorProfiles, name:"color-profiles", meta: {title: "Board Profiles", noAuthRequired: true}},
    {path: "/home/", component: FrontPage, name:"frontpage", meta: {noAuthRequired: true}},
];

const router = createRouter({
    history: createWebHistory(),
    routes: routes,
});

import { setPageTitle } from './assets/js/titleManager.js';

router.beforeEach((to, from, next) => {
    if(to.matched.some(record => !(record.meta.noAuthRequired))){
        if(!localStorage.getItem("jwt")){
            next({name: "frontpage"});
        }else{
            next();
        }
    }else{
        next();
    }

    let title = to.meta.title;
    if (title) {
        for (const param in to.params) {
            title = title.replace(`:${param}`, to.params[param]);
        }
    }
    setPageTitle(title);
});

router.afterEach((to, from) => {
    check_version();
});

const pinia = createPinia();
const app = createApp({});
const head = createHead();

pinia.use(() => ({router: router}));

const build_timestamp = "BUILD_TIMESTAMP";

async function check_version(){
    const app_server = import.meta.env.VITE_APP_SERVER;
    // console.log(app_server);
    // console.log("Local Version", build_timestamp);
    return;

    const response = await fetch(app_server + "/version/",{
        headers:{
            "Content-Type": "application/json",
        },
    });
    const version_data = await response.json();
    const latest_version = new Date(version_data.version.date);

    if( import.meta.env.PROD ){
        const latest_build = new Date(build_timestamp);
        console.log("Latest Version", latest_version);
        console.log("Local Version", latest_build);
        if(!latest_build || latest_build < latest_version){
            // messageStore.alertUser("Outdated version", 
            //     "There is a new version available, refreshing the application in 5 seconds.", {
            //         timeToLive: null,
            // });
            alert("A new version is available, reloading the app...");
            setTimeout(() => location.reload(true), 500);
        }
    }
}

window.addEventListener("beforeunload", function (e)
{
    window.sessionStorage.tabId = window.tabId;

    return null;
});

window.addEventListener("load", function (e){
    if (window.sessionStorage.tabId){
        window.tabId = window.sessionStorage.tabId;
        window.sessionStorage.removeItem("tabId");
    }else{
        window.tabId = Math.floor(Math.random() * 1000000);
    }

    return null;
});


function get_darkmode_preference(){ // Copy from DarkModeToggle.vue
    if( typeof window !== 'undefined' ){
        const preference = localStorage.getItem("themePreference");
        if(preference == null){
            return false;
        }else if(preference == "system"){
            return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches === true;
        }else{
            return preference == "dark";
        }
    }
    return false;
}

document.body.classList.toggle('darkmode', get_darkmode_preference());

app.use(router);
app.use(pinia);
app.use(head);

app.mount('#app')
