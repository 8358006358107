<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var club_id;

const json_fields = ["invited", "members","moderators"]; 
const date_fields = ["create_time"]; 

const extra_data = reactive({
    club: {}
});


onMounted(() => {
    club_id = useRoute().params.club_id;
    load_club();
});

async function save_club(){
    var url = app_server + `/admin/club/${club_id}/`;
    const data = Object.assign({}, extra_data.club);

    for(let field of json_fields){
        data[field] = JSON.parse(data[field]);
    }
    for(let field of date_fields){
        data[field] = new Date(data[field]) / 1000;
    }


    const response = await fetch(url, {
        method: "POST",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify(data),
    });
    
    const club_data = await response.json();
    load_club();
}

async function load_club(){
    var url = app_server + `/admin/club/${club_id}/`;

    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    console.log(data);

    if(data.club){
        extra_data.club = data.club;

        for(let field of json_fields){
            extra_data.club[field] = JSON.stringify(
                data.club[field], null, 4
            );
        }
        for(let field of date_fields){
            if(data.club[field] != null){
                const time_string = new Date(
                    (data.club[field] * 1000) -
                    ((new Date()).getTimezoneOffset()*60*1000)
                ).toISOString().slice(0,16);
                data.club[field] = time_string;
            }
        }
    }
}

</script>

<template>
    <button class="btn btn-blue">
        <router-link :to="{name: 'admin-club'}">
            Club List
        </router-link>
    </button>
    <h1>Club Detail</h1>
    <button @click="save_club()" class="btn btn-blue">Save</button>
    <form class="container flex flex-col max-w-prose mx-4">
        <div v-for="value,field in extra_data.club">
            <label :for="field"
                   class="block text-sm font-medium leading-6"
            >
                {{ field }}
            </label>
            
            <textarea 
                v-model="extra_data.club[field]"
                v-if="['description', 'rruleset', ...json_fields].includes(field)"
                :id="field"
                class="w-full"
            ></textarea>
            <input type="datetime-local" v-else-if="date_fields.includes(field)"
                v-model="extra_data.club[field]"
            >
            <input 
                class="block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 
                        ring-inset ring-gray-300 placeholder:text-gray-400 
                        focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                v-model="extra_data.club[field]"
                v-else
                :id="field"
            />
        </div>
    </form>
</template>

<style>
</style>


