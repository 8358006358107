<script setup>
import {ref, computed, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {UserCircleIcon, FireIcon, TrashIcon, CheckIcon, XMarkIcon} from '@heroicons/vue/24/solid'

import ButtonLoad from './ButtonLoad.vue'
import UserName from './UserName.vue'

const extra_data = reactive({
    total: 0,
    offset:0,
});

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();

const club_list = reactive([]);
onMounted(() => {
    load_club_list();
});

async function load_club_list(){
    var url = app_server + "/club/";
    if(extra_data.offset > 0){
        url += `?offset=${extra_data.offset}`
    }
    
    const response = await fetch(url,{
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const data = await response.json();
    club_list.length = 0;// empty the users list
    if(data.clubs){
        club_list.push(...data.clubs);
    }
    console.log(data);
}

async function remove_club(club_id){
    const response = await fetch(app_server + `/admin/club/${club_id}/`, {
        method: "DELETE",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
    });
    
    const response_data = await response.json();

    console.log(response_data);
    load_club_list();
}

async function create_club(club_id){
    const response = await fetch(app_server + `/admin/club/`, {
        method: "post",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
            "Authorization": "Bearer " + localStorage.getItem("jwt"),
        },
        body: JSON.stringify({
            name: "new club",
            tag: "OG",
        }),
    });
    
    const response_data = await response.json();

    console.log(response_data);
    load_club_list();
}

function next_page(){
    extra_data.offset += 20;
    load_club_list();
}

function previous_page(){
    extra_data.offset = Math.max(0, extra_data.offset - 20);
    load_club_list();
}
</script>

<template>
<div class="flex-col">
<div class="mt-8 flow-root">
<div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
<div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
    <ButtonLoad class="btn btn-blue"
        :fn=create_club
    >
        Create Club
    </ButtonLoad>

<table class="min-w-full divide-y divide-gray-300 table-auto">
<thead>
    <tr>
        <th class="w-4">#</th>
        <th scope="col" class="sticky top-0 z-10">Name</th> 
        <th scope="col" class="sticky top-0 z-10">Creator</th> 
        <th scope="col" class="sticky top-0 z-10">Since</th> 
        <th scope="col" class="sticky top-0 z-10">Members</th> 
        <th scope="col" class="sticky top-0 z-10">Rating</th> 
        <th scope="col" class="sticky top-0 z-10">Exp</th> 
        <th scope="col" class="sticky top-0 z-10"></th> 
    </tr>
</thead>
<tbody>
    <tr  v-if="club_list.length == 0">
    <td
        class="w-full text-center"
        colspan=6>
        No Clubs
    </td>
    </tr>
    <tr v-for="club, index in club_list" class="even:bg-gray-50" >
        <td class="text-left w-4 pl-1">{{ extra_data.offset + index + 1 }}.</td>
        <td class="text-left">
            <router-link :to="{name: 'admin-club-detail', params: {club_id: club.club_id}}">
                {{ club.name }}
            </router-link>
        </td>
        <td class="text-left"><UserName :user_id="club.creator" /></td>
        <td class="text-right">{{ (new Date(club.create_time * 1000)).toLocaleString() }}</td>
        <td class="text-right">{{ club.members.length }} / {{ club.max_members }}</td>
        <td class="text-right">{{ club.rating }}</td>
        <td class="text-right">{{ club.experience }}</td>
        <td>
            <button class="btn" @click="remove_club(club.club_id)">Remove</button>
        </td>
    </tr>
</tbody>
</table>
<div class="w-full relative">
    <button v-show="extra_data.offset > 0" class="btn btn-blue m-4" @click="previous_page()">
        Previous
    </button>
    <button @click="next_page()" class="float-right btn btn-blue m-4">
        Next
    </button>
</div>
</div>
</div>
</div>
</div>
</template>

<style>
</style>


