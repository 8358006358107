import { defineStore } from 'pinia'
import { Howl, Howler } from 'howler'


import attentionAudio from '../assets/audio/attention.wav'
import {updateTitleWithNotifications} from '../assets/js/titleManager.js'

const app_server = import.meta.env.VITE_APP_SERVER;

const getUA = () => {
    let device = "Unknown";
    const ua = {
        "Generic Linux": /Linux/i,
        "Android": /Android/i,
        "BlackBerry": /BlackBerry/i,
        "Bluebird": /EF500/i,
        "Chrome OS": /CrOS/i,
        "Datalogic": /DL-AXIS/i,
        "Honeywell": /CT50/i,
        "iPad": /iPad/i,
        "iPhone": /iPhone/i,
        "iPod": /iPod/i,
        "macOS": /Macintosh/i,
        "Windows": /IEMobile|Windows/i,
        "Zebra": /TC70|TC55/i,
    }
    Object.keys(ua).map(v => navigator.userAgent.match(ua[v]) && (device = v));
    return device;
}


export const useMessageStore = defineStore('message', {
    state: () => { return {
        messages: [],
    };},
    getters: {
        getMessages : (state) => state.messages,
    },
    actions:{
        alertUser(title, text, user_options={}){
            const message = {
                title: title,
                text: text,
                show: true,
                id: Date.now(),
                timeout_id: null,
                url: user_options.url,
            };

            const options = {
                timeToLive: 5000,
                showNotification: false,
                playSound: false,
            };

            Object.assign(options, user_options);

            const message_index = this.messages.findIndex(x => x.title == title);

            if(message_index < 0){
                this.messages.push(message);
            }else{
                Object.assign(this.messages[message_index], message);
            }

            clearTimeout(message.timeout_id);

            if(options.timeToLive != null){
                message.timeout_id = setTimeout(() => {
                    this.removeMessage(message.id);
                }, options.timeToLive);
            }

            if(options.showNotification && Notification.permission == "granted"){
                let icon = "https://beta.opengammon.com/favicon-32x32.png";
                let notification = new Notification(title, {body:text, icon:icon});
                notification.onclick = function(){
                    window.parent.focus();
                    notification.close();
                };
            }
            if(options.showNotification && 'navigator' in window && 'vibrate' in window.navigator){
                // Give a short vibration to alert the user something happened
                window.navigator.vibrate(200);
            }

            if(options.playSound){
                var sound = new Howl({
                    src: [attentionAudio],
                    volume: 0.5,
                });
                sound.play();
            }
            
            this.updateTitle();
        },
        removeMessage(message_id){
            const index = this.messages.findIndex((x) => x.id == message_id);
            this.messages.splice(index, 1);
            this.updateTitle();
        },
        updateTitle() {
            updateTitleWithNotifications(this.messages.length);
        },
        urlB64ToUint8Array(base64String) {
            const padding = '='.repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
                .replace(/\-/g, '+')
                .replace(/_/g, '/');

            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        },
        async get_public_key(){
            const response = await fetch(app_server + `/push/subscribe/`, {
                method: "GET",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
            });

            const data = await response.json(); 
            if(data.public_key == null){
                return null
            }

            return this.urlB64ToUint8Array(data.public_key);
        },

        async send_subscription(subscription){
            const response = await fetch(app_server + `/push/subscribe/`, {
                method: "POST",
                headers:{
                    "Content-Type": "application/json",
                    "Authorization": "Bearer " + localStorage.getItem("jwt"),
                },
                body: JSON.stringify({
                    subscription_token: subscription,
                    name: getUA(),
                }),
            });
            const data = await response.json(); 

            if(data.status == "error"){
                console.error("Could not subscribe to push", data.message);
                return false;
            }
            return data;
        },

        async subscribe_to_push(){
            if( localStorage.getItem('push_subscription') != null){
                console.error("Already subscribed", localStorage.getItem('push_subscription'));
                // return;
            }
            if (!("serviceWorker" in navigator)){
                return;
            }

            const public_key = await this.get_public_key();

            if(public_key == null){
                console.error("No valid public VAPID key");
                return;
            }
            const registration = await navigator.serviceWorker.getRegistration();
            if (registration == null) {
                console.error("Could not find serviceworker");
                return;
            }

            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey:public_key, 
            });

            const subscriptions_data = this.send_subscription(subscription);

            if(!send_succeeded){
                console.error("Could not find serviceworker");
                return subscriptions_data;
            }
            this.alertUser("Subscribed to Push messsages.", "You can now receive push messages");
            localStorage.setItem("push_subscription", JSON.stringify(subscription));

            return subscriptions_data;
        },

        add_pushlistener(){
            
        },
    }
});
