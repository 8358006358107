<script setup>
import {ref, computed, watch, reactive, onMounted} from 'vue'
import { useRoute, useRouter } from 'vue-router'
import markdownit from 'markdown-it'
import {ClockIcon, UserCircleIcon, MagnifyingGlassIcon, FireIcon, CheckIcon} from '@heroicons/vue/24/solid'

import Header from './Header.vue'
import ButtonLoad from './ButtonLoad.vue'

const app_server = import.meta.env.VITE_APP_SERVER;
const router = useRouter();
var slug;

const props = defineProps({
});

const page_size = 20;

const extra_data = reactive({
    posts: [],
    offset: 0,
    total: 0,
    search: "",
});


onMounted(() => {
    get_posts();
});

async function get_posts(){
    var url = app_server + `/blog/`;
    
    const get_parameters = {
        offset: extra_data.offset,
        search: extra_data.search,
    };
    const search_params = new URLSearchParams(get_parameters)


    const response = await fetch(url + "?" + search_params.toString(), {
        method: "GET",
        mode: "cors",
        headers:{
            "Content-Type": "application/json",
        },
    });
    
    const blog_data = await response.json();
    if(blog_data.status == "success"){
        extra_data.posts = blog_data.posts;
        extra_data.total = blog_data.total;
    }    

    console.log(blog_data);
}

async function next(delta){
    var new_offset = extra_data.offset + delta;

    new_offset = Math.min(new_offset, extra_data.total - page_size);
    new_offset = Math.max(0, new_offset);

    extra_data.offset = new_offset;

    await get_posts();
}

</script>

<template>
    <Header />
    <div class="container mx-auto flex flex-col items-center gap-y-8">
    <h1 class="text-4xl font-semibold text-center mt-8 tracking-wide">
        OpenGammon Blog
    </h1>
    <div class="flex items-center gap-x-1">
        <input type="text" class="h-10" v-model="extra_data.search"/>
        <ButtonLoad
            :fn="get_posts"
            class="cursor-pointer size-8"
        >
            <MagnifyingGlassIcon 
                class="size-8 hover:text-accent-3-color" 
            />
        </ButtonLoad>

    </div>
    <div class="grid grid-cols-1 md:grid-cols-2 w-full gap-8">
        <div class="flex box bg-main-2-color shadow rounded py-4 px-6 group relative transition-all" 
            v-for="post in extra_data.posts"
        >
            <div class="grow">
                <router-link :to="{name: 'blog-article', params:{slug: post.slug}}">
                    <h2 class="text-3xl text-center tracking-wide font-medium mb-4 group-hover:text-accent-3-color">{{ post.title }}</h2>
                </router-link>
                <div class="flex gap-x-4 mt-3 opacity-60 group-hover:opacity-100">
                    <div class="flex gap-x-1 items-center">
                        <UserCircleIcon class="w-4 h-4" />
                        <span class="text-right">{{ post.author.username }}</span>
                    </div>
                    <div class="flex gap-x-1 items-center">
                        <ClockIcon class="w-4 h-4" />
                        <span>{{ new Date(post.update_time*1000).toDateString() }}</span>
                    </div>
                </div>
                <div class="mt-4 text-lg font-thin overflow-y-hidden h-24 relative">
                    <div class="absolute bottom-0 w-full h-10 bg-gradient-to-t from-main-2-color" />
                    {{ post.summary }}
                </div>
            </div>
            <div class="absolute w-full flex justify-center bottom-4 opacity-0 group-hover:opacity-100 transition-opacity delay-500">
                <router-link :to="{name: 'blog-article', params:{slug: post.slug}}">
                    <button class="btn btn-blue h-10 w-32">Read More</button>
                </router-link>
            </div>
        </div>
    </div>
    <div class="flex justify-between w-full px-2 mb-8">
        <ButtonLoad 
            class="btn btn-blue"
            :fn="() => next(-page_size)"
            :disabled="extra_data.offset <= 0"
        >
            Previous
        </ButtonLoad>
        <span>{{ extra_data.total }} Articles to read</span>
        <ButtonLoad 
            class="btn btn-blue"
            :fn="() => next(page_size)"
            :disabled="extra_data.offset + page_size >= extra_data.total"
        >
            Next
        </ButtonLoad>
    </div>
    </div>
</template>

<style scoped>
</style>

