<script setup>

import {ref, watch, computed, reactive, onMounted, onUnmounted} from 'vue'
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'

import {useMatchStore } from '@/stores/matchstore.js'
const matchStore = useMatchStore();

const app_server = import.meta.env.VITE_APP_SERVER;

const emit = defineEmits([
    'match-joined',
    'cancel',
]);
const props = defineProps(
);

var counter_interval_id = null;
var hints = [
    "Did you know you can draw arrows on the board using ctrl-click? You can remove them with the 'a' button.",
    "Did you try Puzzle Rush? You can find it in the Puzzle menu.",
    "You can view your play insights in your profile page.",
    "If you want to improve you should really try the quiz. It is made especially for you to train your weak spots.",
    "You can create your avatar in your profile page using the achievements you earn playing.",
    "Did you know you can conclude your move by pressing the space-bar? This can be a life saver in bullet games.",
    "If you have comments or questions you can always send us an email or send us a chat message",
    "You can highlight points on the board by shift clicking them. You can remove the highlight by pressing 'a'.",
    "Ranking high in a tournament grants you Master Points (MP).",
    "By winning a tournament you will earn an achievement.",
    "You can turn on notifications to get notified of others joining your match, or a match starting.",
    "You can turn on push notifications so you also get notifications on your phone.",
    "Have you tried changing the board theme in the preferences?",
    "You can chat with other users in and outside of games.",
    "Have you tried unlimited games? They allow you to win sparkles!",
    "Your sparkles will be reset to 800 each first day of the month.",
    "You can upgrade the analysis of your match to 3-ply to get more accurate results.",
    "The best way to train is by solving puzzles!",
    "The puzzles on OpenGammon are created from your (and other's) mistakes, so every puzzle is a real position.",
    "Make sure to browse the puzzle comment section once in a while. It can give you great insights into why the solution is correct.",
    "Commenting on puzzles can earn you legendary achievements.",
];


const extra_data = reactive({
    counter: 0,
    current_hint: 0,
});

onMounted(() =>{ 
    matchStore.setup();
    extra_data.counter = 0;
    get_random_hint();
});

onUnmounted(() =>{ 
    extra_data.counter = 0;
    if(props.match_id){
        clearInterval(counter_interval_id);
    }
});

function get_random_hint(){
    extra_data.current_hint = Math.floor(Math.random() * hints.length);
}

const modalFocus = ref(null); // Needed for the Dialog

</script>
<template>
    <TransitionRoot as="template" 
        :show="matchStore.active_joinrequest != null"
    >
    <Dialog as="div" 
            class="relative z-10" 
            @close=""
            :initialFocus="modalFocus"
    >
      <TransitionChild as="template" enter="ease-out duration-300" 
        enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" 
        leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      </TransitionChild>

      <div class="fixed inset-0 z-10 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-main-2-color px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <h1>Waiting for the opponent to accept...</h1>
            <h1 class="mx-auto text-xl font-semibold text-center my-4">
                {{ matchStore.current_join_counter }}
            </h1>
            <div class="mt-8 mb-4">
                <p class="font-thin text-center">
                    {{ hints[extra_data.current_hint] }}
                </p>
            </div>
            <button class="btn btn-red mt-2 w-full" 
                @click="matchStore.cancel_join()"
            >
                Cancel
            </button>
        
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<style scoped>
</style>
